<template>
  <v-app id="inspire">
    <div class="loading_initial" v-if="!loaded && user.company_id != 100000">
      <div>
        <h4><v-icon size="100" color="#DCDCDC">mdi-hoop-house</v-icon></h4>
      </div>
      <div class="progress_linear">
        <v-progress-linear
          indeterminate
          color="#C0C0C0"
          height="6"
          rounded
        ></v-progress-linear>
      </div>
    </div>

    <div class="loading_initial" v-if="user.company_id == 100000">
      <div>
        <h4><v-icon size="100" color="#696969">mdi-hoop-house</v-icon></h4>
        <br />
        <h3>Sistema em Manutenção! NÃO ENCHE!!</h3>
      </div>
    </div>

    <Navbar v-if="notIsLoginPage && loaded && user.company_id != 100000" />

    <Sidebar v-if="notIsLoginPage && loaded && user.company_id != 100000" />

    <v-main class="main_app" v-if="loaded">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Vue from "vue";
import { mapState, mapActions } from "vuex";

var eventBus = new Vue();

export default {
  name: "App",

  components: {
    Navbar,
    Sidebar,
  },

  data: () => ({
    loaded: false,
  }),

  computed: {
    ...mapState("auth", ["user"]),

    notIsLoginPage() {
      return this.$route.name !== "login";
    },
  },

  created() {
    //console.log("CREATED");
  },

  mounted() {
    //console.log("MOUNTED");
    this.initialize();
  },

  methods: {
    ...mapActions("auth", ["getMe"]),
    ...mapActions("general", ["getCompany"]),

    async initialize() {
      //console.log("INITIALIZE INI");
      try {
        await this.getMe();
        var body = {
          id: this.user.company_id,
        };
        await this.getCompany(body);
        //this.$router.push('/company');
      } catch {}
      this.loaded = true;
      //console.log("INITIALIZE FIM");
    },
  },
};
</script>

<style scoped>
.main_app {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
}
.loading_initial {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.progress_linear {
  width: 100px;
}

h3 {
  color: #696969;
}
</style>
