import * as types from "./mutation-types";
import { http } from "../../../../services/config";

export const getCashregisters = async ({ commit }, payload) => {
  await http
    .post("cashregisters", payload, {})
    .then((resp) => {
      for (var i = 0; i < resp.data.length; i++) {
        var data = new Date(resp.data[i].date_open);
        //var data_filho = new Date();
        resp.data[i].date_open = data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        resp.data[i].price_total = parseFloat(resp.data[i].price_total)
          .toLocaleString("en-us", { minimumFractionDigits: 2 })
          .replace(",", "");
        //resp.data[i].cost = resp.data[i].cost.toLocaleString('en-us', {minimumFractionDigits: 2}).replace(',', '');
      }

      commit(types.GET_AMOUNT, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      //console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const addCashregisters = async ({ commit }, payload) => {

  var dateTime = new Date();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  var second = dateTime.getSeconds();
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var time = hour + ":" + minute + ":" + second;
  var data = new Date();
  var date_add = data.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
  var date_open =
    date_add.substring(6, 10) +
    "-" +
    date_add.substring(3, 5) +
    "-" +
    date_add.substring(0, 2);

  payload.date_open = date_open + " " + time,

  await http
    .post("cashregister", payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const updateCashregisters = async ({ commit }, payload) => {
  var route = "cashregister/" + payload.id;
  
  await http
    .put(route, payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const closeCashregisters = async ({ commit }, payload) => {

  var route = "closecashregister/" + payload.id;

    var dateTime = new Date();
    var hour = dateTime.getHours();
    var minute = dateTime.getMinutes();
    var second = dateTime.getSeconds();
    if (hour.toString().length == 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length == 1) {
      minute = "0" + minute;
    }
    if (second.toString().length == 1) {
      second = "0" + second;
    }
    var time = hour + ":" + minute + ":" + second;
    var data = new Date();
    var date_current = data.toLocaleDateString("pt-BR", {
      timeZone: "UTC",
    });
    var date_close =
      date_current.substring(6, 10) +
      "-" +
      date_current.substring(3, 5) +
      "-" +
      date_current.substring(0, 2);
    payload.date_close = date_close + " " + time;
    await http
    .put(route, payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      //console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const deleteCashregisters = async ({ commit }, payload) => {
  var route = "cashregister/" + payload.id;
  await http
    .delete(route, {})
    .then((resp) => {
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const clearCashregisters = async ({ commit }, payload) => {
  await commit(types.GET_AMOUNT, []);
};

export const setErrorApi = async ({ commit }, payload) => {
  await commit(types.SET_ERROR, payload);
};
