import Cookie from "js-cookie";
import Auth from "./auth";

export default {
  async auth(to, from, next) {
    var permission = "";

    const token = Cookie.get("_my_token");

    const payload = "bearer" + token;
    await Auth.me(token)
      .then((response) => {
        permission = response.data.level_user;
        if (!token) {
          next("/login");
        } else {
          if (to.name == "company") {
            if (!(permission == "Super")) {
              next("/nopermission");
            }
          }

          if (permission == "Super") {
            if (
              !(
                to.name == "company" ||
                to.name == "user" ||
                to.name == "profile"
              )
            ) {
              next("/nopermission");
            }
          }
          if (permission == "Encaregado") {
            if (
              !(
                to.name == "home" ||
                to.name == "amount" ||
                to.name == "amountdetail" ||
                to.name == "amountpanel" ||
                to.name == "demandpanel" ||
                to.name == "profile"
              )
            ) {
              next("/nopermission");
            }
          }

          if (permission == "Operador") {
            if (
              !(
                to.name == "home" ||
                to.name == "amount" ||
                to.name == "amountdetail" ||
                to.name == "amountpanel" ||
                to.name == "demandpanel" ||
                to.name == "profile"
              )
            ) {
              next("/nopermission");
            }
          }

          if (permission == "Caixa") {
            if (
              !(
                to.name == "home" ||
                to.name == "demand" ||
                to.name == "amount" ||
                to.name == "cashregister" ||
                to.name == "amountpanel" ||
                to.name == "demandpanel" ||
                to.name == "profile"
              )
            ) {
              next("/nopermission");
            }
          }
        }

        next();
      })
      .catch((error) => {
        next("/login");
      });
  },
  methods: {
    permissionValidate() {},
  },
};
