<template>
  <v-navigation-drawer v-model="drawer" width="230" app dark>
    <div class="name_app">
      <h4><v-icon size="30px" color="#DCDCDC">mdi-hoop-house</v-icon></h4>
      &nbsp;
      <h5>KiosK System</h5>
    </div>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-if="isPermission(item.title)"
        v-for="item in items"
        :key="item.title"
        :to="item.to"
        link
      >
        <v-list-item-icon
          v-if="item.title != 'Relatórios' && item.title != 'Painéis' && item.title != 'Meu Perfil'"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          v-if="item.title != 'Relatórios' && item.title != 'Painéis' && item.title != 'Meu Perfil'"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>

        <v-menu v-if="item.title == 'Painéis'" offset-x>
          <template v-slot:activator="{ attrs, on }">
            <div class="menu_report" v-bind="attrs" v-on="on">
              <v-list-item-icon v-if="item.title == 'Painéis'">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="item.title == 'Painéis'">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </div>
          </template>

          <v-list dark>
            <v-list-item
              v-for="item in items_panel"
              :key="item.title"
              :to="item.to"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-if="item.title == 'Relatórios'" offset-x>
          <template v-slot:activator="{ attrs, on }">
            <div class="menu_report" v-bind="attrs" v-on="on">
              <v-list-item-icon v-if="item.title == 'Relatórios'">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="item.title == 'Relatórios'">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </div>
          </template>

          <v-list dark>
            <v-list-item
              v-for="item in items_report"
              :key="item.title"
              :to="item.to"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item-icon
          @click="dialog_user = true"
          v-if="item.title == 'Meu Perfil'"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          @click="dialog_user = true"
          v-if="item.title == 'Meu Perfil'"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="user_logged">
        <h4>
          <v-icon size="30px" color="#DCDCDC">mdi-account-check-outline</v-icon>
          {{ user.email }}
        </h4>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Cookie from "js-cookie";
import User from "../services/user";
import { eventBus } from "../main";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Navbar",

  mounted() {
    this.initialize();
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("general", ["drawer"]),
    ...mapGetters("general",{
      drawerFromStore: "drawer",
    }),
    drawer: {
      get() {
        return this.drawerFromStore;
      },
      set(newDrawer) {
        return newDrawer;
      },
    },
  },

  data() {
    return {
      edit_user: 1,
      dialog_user: false,
      user_logged: "",
      user_level: "",
      items: [
        { 
          title: "Home", 
          icon: "mdi-home-outline", 
          to: "/" 
        },
        { title: "Painéis", icon: "mdi-view-dashboard" },
        {
          title: "Fichas",
          icon: "mdi-folder-open-outline",
          to: "/amount",
        },
        {
          title: "Caixas",
          icon: "mdi-cash-register",
          to: "/cashregister",
        },
        //{
        //  title: "Pedidos",
        //  icon: "mdi-note-edit-outline",
        //  to: "/demand",
        //},
        { title: "Relatórios", icon: "mdi-finance" },
        { title: "Produtos", icon: "mdi-food-outline", to: "/product" },
        {
          title: "Usuários",
          icon: "mdi-account-supervisor",
          to: "/user",
        },
        {
          title: "Empresas",
          icon: "mdi-home-city-outline",
          to: "/company",
        },
        {
          title: "Meu Perfil",
          icon: "mdi-account-box",
          to: "/profile",
        },
        {
          title: "Configurações",
          icon: "mdi-cog-outline",
          to: "/settings",
        },
      ],
      items_report: [
/*        {
          title: "Pedidos",
          icon: "mdi-desktop-mac",
          to: "/report/demand",
        },
        {
          title: "Fichas",
          icon: "mdi-folder-open-outline",
          to: "/report/amount",
        },
        {
          title: "Caixa",
          icon: "mdi-cash-register",
          to: "/report/cashregister",
        }, */
        {
          title: "Vendas/Faturamento",
          icon: "mdi-cash-plus",
          to: "/report/billing",
        },
      ],
      items_panel: [
        {
          title: "Fichas",
          icon: "mdi-folder-open-outline",
          to: "/amountpanel",
        },
        {
          title: "Pedidos",
          icon: "mdi-note-edit-outline",
          to: "/demandpanel",
        },
      ],
      right: null,
    };
  },

  methods: {
    async initialize() {
      //console.log("INITIALIZE MENU");
      var token = Cookie.get("_my_token");

      await User.get(token)
        .then((response) => {
          this.user_logged = response.data.email;
          this.user_level = response.data.level_user;
        })
        .catch((error) => {});
    },

    isPermission(menuItem) {
      var permission = this.user.level_user;

      if (this.user.level_user == "Administrador") {
        // ADMIN
        if (
          menuItem == "Home" ||
          menuItem == "Painéis" ||
          menuItem == "Pedidos" ||
          menuItem == "Fichas" ||
          menuItem == "Caixas" ||
          menuItem == "Relatórios" ||
          menuItem == "Produtos" ||
          menuItem == "Usuários" ||
          menuItem == "Meu Perfil" ||
          menuItem == "Configurações"
        ) {
          return true;
        }
      }

      if (this.user.level_user == "Operador" || this.user.level_user == "Encarregado") {
        // OPERADOR/ENCAREGADO
        if (
          menuItem == "Home" ||
          menuItem == "Painéis" ||
          menuItem == "Pedidos" ||
          menuItem == "Fichas" ||
          menuItem == "Meu Perfil"
        ) {
          return true;
        }
      }

      if (this.user.level_user == "Caixa") {
        // CAIXA
        if (
          menuItem == "Home" ||
          menuItem == "Painéis" ||
          menuItem == "Pedidos" ||
          menuItem == "Fichas" ||
          menuItem == "Caixas" ||
          menuItem == "Meu Perfil"
        ) {
          return true;
        }
      }

      if (this.user.level_user == "Super") {
        // SUPER ADMIN
        if (
          menuItem == "Empresas" ||
          menuItem == "Usuários" ||
          menuItem == "Meu Perfil"
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.main {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.menu_report {
  width: 450px;
  display: flex;
}

.report {
  width: 100%;
}
.name_app {
  font-family: Dejavu Sans;
  color: #fff;
  height: 66px;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-left: 32px;
  padding-right: 32px;
}

.logout {
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.user_logged {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: left;
  padding-left: 10px;
  align-items: center;
  color: #fff;
  background: #1c1c1c;
}

h5 {
  font-size: 18px;
  color: #dcdcdc;
}

h4 {
  font-size: 16px;
  color: #dcdcdc;
}

a {
  text-decoration: none;
}
</style>
