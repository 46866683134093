export default {
  drawer: true,
  auto_reload: '',
  time_reload: '',
  use_colors: '',
  amount_home: '',
  demand_home: '',
  cashregister_home: '',
  name_company: '',
  error_api: false,
  api_fail_general: false,
}