import * as types from './mutation-types';

export default {
  [types.SET_DEMAND] (state, payload){
    state.demands = payload;
  },
  [types.SET_MESSAGE] (state, payload){
    state.message = payload;
  },
  [types.SET_ERROR] (state, payload){
    state.error_api_demand = payload;
  },
}