import * as types from "./mutation-types";
import { http } from "../../../services/config";

export const getCashregisters = async ({ commit }, payload) => {
  var value_final_calc = 0;
  var value_final_origin_calc = 0;
  await http
    .post("cashregisters", payload, {})
    .then((resp) => {
      commit(types.SET_CASHREGISTER, resp.data.cashregister);
      if (resp.data.cashregister.status == "Aberto") {
        value_final_calc = parseFloat(resp.data.cashregister.value_initial);
        value_final_origin_calc = parseFloat(resp.data.cashregister.value_initial);
        for (var i = 0; i < resp.data.itemscashregister.length; i++) {
          if (resp.data.itemscashregister[i].operation_type == "Entrada") {
            value_final_calc =
              value_final_calc + parseFloat(resp.data.itemscashregister[i].value_register);
            value_final_origin_calc =
              value_final_origin_calc +
              parseFloat(resp.data.itemscashregister[i].value_register);
            } else {
            value_final_calc =
              value_final_calc - parseFloat(resp.data.itemscashregister[i].value_register);
            value_final_origin_calc =
              value_final_origin_calc -
              parseFloat(resp.data.itemscashregister[i].value_register);
            }
          var data_itemscashregister = new Date(
            resp.data.itemscashregister[i].date
          );
          resp.data.itemscashregister[i].date =
            data_itemscashregister.toLocaleString("pt-br");
          resp.data.itemscashregister[i].value_register =
            parseFloat(resp.data.itemscashregister[i].value_register).toLocaleString(
              "en-us",
              {
                minimumFractionDigits: 2,
              }
            );
        }
      }
      commit(types.GET_ITEMCASHREGISTER, resp.data.itemscashregister);
      commit(
        types.SET_USERRESPONSIBLE,
        resp.data.cashregister.responsible_user
      );
      if (resp.data.cashregister.status == "Aberto") {
        commit(types.SET_COLORSTATUS, "green");
        commit(types.SET_OPENCASHREGISTER, true);
      } else if (resp.data.cashregister.status == "Novo") {
        commit(types.SET_COLORSTATUS, "red");
        commit(types.SET_OPENCASHREGISTER, false);
      }

      if (resp.data.cashregister.value_initial >= 0) {
        commit(types.SET_COLORCASHREGISTER, "green");
      } else {
        commit(types.SET_COLORCASHREGISTER, "red");
      }

      if (resp.data.cashregister.status == "Aberto") {
        var data = new Date(resp.data.cashregister.date_initial);
      }else{
        var data = new Date();
      }
      commit(
        types.SET_DATECASHOPEN,
        data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })
      );

      commit(
        types.SET_VALUEINITIAL,
        parseFloat(resp.data.cashregister.value_initial).toLocaleString("en-us", {
          minimumFractionDigits: 2,
        })
      );

      commit(
        types.SET_VALUEFINAL, //value_final_calc
        parseFloat(value_final_calc).toLocaleString("en-us", {
          minimumFractionDigits: 2,
        })
      );

      commit(types.SET_VALUEFINALORIGIN, value_final_calc);

      if (value_final_calc >= 0) {
        commit(types.SET_COLORSCURRENTCASH, "green");
      } else {
        commit(types.SET_COLORSCURRENTCASH, "red");
      }

      commit(types.SET_ERROR, false);
      /*  
      for (var i = 0; i < resp.data.length; i++) {
        var data = new Date(resp.data[i].date_open);
        //var data_filho = new Date();
        resp.data[i].date_open = data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        resp.data[i].price_total = resp.data[i].price_total
          .toLocaleString("en-us", { minimumFractionDigits: 2 })
          .replace(",", "");
        //resp.data[i].cost = resp.data[i].cost.toLocaleString('en-us', {minimumFractionDigits: 2}).replace(',', '');
      }

      commit(types.GET_AMOUNT, resp.data);
      commit(types.SET_ERROR, false); */
    })
    .catch((error) => {
      //console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const getOneCashregisters = async ({ commit }, payload) => {
  await http
    .post("cashregister/one", payload, {})
    .then((resp) => {
      for (var i = 0; i < resp.data.length; i++) {
        var data = new Date(resp.data[i].date_open);
        //var data_filho = new Date();
        resp.data[i].date_open = data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        resp.data[i].price_total = resp.data[i].price_total
          .toLocaleString("en-us", { minimumFractionDigits: 2 })
          .replace(",", "");
        //resp.data[i].cost = resp.data[i].cost.toLocaleString('en-us', {minimumFractionDigits: 2}).replace(',', '');
      }

      commit(types.GET_AMOUNT, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      //console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const addCashregisters = async ({ commit }, payload) => {
  var dateTime = new Date();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  var second = dateTime.getSeconds();
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var time = hour + ":" + minute + ":" + second;
  var data = new Date();
  var date_add = data.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
  var date_open =
    date_add.substring(6, 10) +
    "-" +
    date_add.substring(3, 5) +
    "-" +
    date_add.substring(0, 2);

  (payload.date_open = date_open + " " + time),
    await http
      .post("cashregister", payload, {})
      .then((resp) => {
        commit(types.SET_MESSAGE, resp.data);
        commit(types.SET_ERROR, false);
      })
      .catch((error) => {
        commit(types.SET_ERROR, true);
      });
};

export const addItemCashregisters = async ({ commit }, payload) => {
  var dateTime = new Date();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  var second = dateTime.getSeconds();
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var time = hour + ":" + minute + ":" + second;
  var data = new Date();
  var date_add = data.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
  var date_open =
    date_add.substring(6, 10) +
    "-" +
    date_add.substring(3, 5) +
    "-" +
    date_add.substring(0, 2);

  (payload.date_open = date_open + " " + time),
    await http
      .post("cashregister", payload, {})
      .then((resp) => {
        commit(types.SET_MESSAGE, resp.data);
        commit(types.SET_ERROR, false);
      })
      .catch((error) => {
        commit(types.SET_ERROR, true);
      });
};

export const updateCashregisters = async ({ commit }, payload) => {
  var route = "cashregister/" + payload.id;

  await http
    .put(route, payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const closeCashregisters = async ({ commit }, payload) => {
  var route = "closecashregister/" + payload.id;

  var dateTime = new Date();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  var second = dateTime.getSeconds();
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var time = hour + ":" + minute + ":" + second;
  var data = new Date();
  var date_current = data.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
  var date_close =
    date_current.substring(6, 10) +
    "-" +
    date_current.substring(3, 5) +
    "-" +
    date_current.substring(0, 2);
  payload.date_close = date_close + " " + time;
  await http
    .put(route, payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      //console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const deleteCashregisters = async ({ commit }, payload) => {
  var route = "cashregister/" + payload.id;
  await http
    .delete(route, {})
    .then((resp) => {
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const clearCashregisters = async ({ commit }, payload) => {
  await commit(types.GET_AMOUNT, []);
};

export const setErrorApi = async ({ commit }, payload) => {
  await commit(types.SET_ERROR, payload);
};
