import * as types from './mutation-types';

export default {
  [types.GET_AMOUNT] (state, payload){
    state.amounts = payload;
  },
  [types.ADD_AMOUNT] (state, payload){
    state.products = payload;
  },
  [types.SET_EXPANDED] (state, payload){
    state.expanded_amount = payload;
  },
  [types.SET_MESSAGE] (state, payload){
    state.message = payload;
  },
  [types.SET_ERROR] (state, payload){
    state.error_api = payload;
  },
}