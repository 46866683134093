import * as types from './mutation-types';

export default {
  [types.SET_DRAWER] (state, payload){
    state.drawer = payload;
  },
  [types.SET_AUTORELOAD] (state, payload){
    state.auto_reload = payload;
  },
  [types.SET_TIMERELOAD] (state, payload){
    state.time_reload = payload;
  },
  [types.SET_USECOLORS] (state, payload){
    state.use_colors = payload;
  },
  [types.SET_AMOUNTHOME] (state, payload){
    state.amount_home = payload;
  },
  [types.SET_DEMANDHOME] (state, payload){
    state.demand_home = payload;
  },
  [types.SET_CASHREGISTERHOME] (state, payload){
    state.cashregister_home = payload;
  },
  [types.SET_COMPANYNAME] (state, payload){
    state.name_company = payload;
  },
  [types.SET_ERROR] (state, payload){
    state.error_api = payload;
  },
  [types.SET_API_FAIL] (state, payload){
    state.api_fail_general = payload;
  },
}