export default {
  headers: [
    {
      text: "Nome",
      align: "start",
      value: "name",
    },
    { text: "Abertura", value: "date_open" },
    { text: "Descrição", value: "description" },
    { text: "Total", value: "price_total" },
    { text: "Ações", value: "actions", sortable: false },
  ],
  amounts: [],
  payment_type_add_items: [
    { text: "Dinheiro", value: "Dinheiro" },
    { text: "Cartão", value: "Cartão" },
    { text: "Cheque", value: "Cheque" },
    { text: "PIX", value: "PIX" },
    { text: "Transferência", value: "Transferência" },
    { text: "Depósito", value: "Depósito" },
  ],
  message: '',
  error_api: false,
}