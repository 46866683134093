import { http } from "./config";

export default {
  login: (usuario) => {
    return http.post("auth/login", usuario);
  },

  me: (token) => {
    const payload = "Bearer " + token;
    var config = {
      headers: { Authorization: "Bearer " + token },
    };
    return http.post("auth/me", {}, config);
  },
};
