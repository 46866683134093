import state from './state';
import mutations from './mutations';
import mutationsTypes from './mutation-types';
import * as actions from './actions';
import * as getters from './getters';
import * as setters from './setters';

export default {
  state,
  actions,
  mutations,
  mutationsTypes,
  getters,
  setters,
  namespaced: true
}

