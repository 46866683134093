<template>
  <v-app-bar app dark>
    <v-app-bar-nav-icon @click="closeNavbar"
    ></v-app-bar-nav-icon>

    <v-toolbar-title>{{ name_company }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="logout">
      <v-btn icon @click="logout()">
        Sair
        <v-spacer></v-spacer>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import Cookie from "js-cookie";
import { eventBus } from "../main";
import User from "../services/user";
import Company from "../services/company";
import { mapState, mapActions } from 'vuex';

export default {
  name: "Sidebar",

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("general", ["drawer","name_company"]),
  },

  methods: {

    ...mapActions("general", ["setDrawer"]),
    

    async logout() {
      Cookie.remove("_my_token");
      await location.reload();     
    },

    closeNavbar() {
      this.setDrawer(!this.drawer);
    },

    closeNavbarOut() {
      if (!this.drawer){
        this.setDrawer(!this.drawer);
      }
    },

  },

};
</script>
