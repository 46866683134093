export default {
  headers: [
    {
      text: "Código",
      align: "start",
      value: "code",
      divider: true
    },
    { text: "Descrição", value: "description", divider: true },
    { text: "Preço (R$)", value: "price", align: "end", divider: true },
    { text: "Custo (R$)", value: "cost", align: "end", divider: true },
    { text: "Ações", value: "actions", sortable: false },
  ],
  products: [],
  message: '',
  error_api: false,
}