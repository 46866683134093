import * as types from './mutation-types';

export default {
  [types.GET_ITEMAMOUNT] (state, payload){
    state.itemamounts = payload;
  },
  [types.ADD_ITEMAMOUNT] (state, payload){
    state.itemamounts = payload;
  },
  [types.SET_EXPANDED] (state, payload){
    state.expanded = payload;
  },
  [types.SET_MESSAGE] (state, payload){
    state.message = payload;
  },
  [types.SET_ERROR] (state, payload){
    state.error_api = payload;
  },
}