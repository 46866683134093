import * as types from "./mutation-types";
import { http } from "../../../services/config";

export const getItemDemands = async ({ commit }, payload) => {
  await http
    .post("itemamounts", payload, {})
    .then((resp) => {
      commit(types.SET_DEMAND, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const updateItemDemands = async ({ commit }, payload) => {
  var route = "product/" + payload.id;
  await http.put(route, payload, {}).then((resp) => {
    commit(types.SET_MESSAGE, resp.data);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const setErrorApi = async ({ commit }, payload) => {
  await commit(types.SET_ERROR, payload);
};

