export default {
  headersSuper: [
    {
      text: "Nome",
      align: "start",
      value: "name",
      divider: true
    },
    { text: "Usuário", value: "email", divider: true },
    { text: "Perfil", value: "level_user", divider: true },
    { text: "Empresa", value: "company_id", divider: true },
    { text: "Status", value: "active", divider: true },
    { text: "Ações", value: "actions", sortable: false },
  ],
  headers: [
    {
      text: "Nome",
      align: "start",
      value: "name",
      divider: true
    },
    { text: "Usuário", value: "email", divider: true },
    { text: "Perfil", value: "level_user", divider: true },
    { text: "Status", value: "active", divider: true },
    { text: "Ações", value: "actions", sortable: false },
  ],
  users: [],
  titleTable: 'USUÁRIOS',
  message: '',
  error_api: false,
}