import * as types from "./mutation-types";
import { http } from "../../../../services/config";

export const getAmounts = async ({ commit }, payload) => {
  await http
    .post("amounts", payload, {})
    .then((resp) => {
      for (var i = 0; i < resp.data.length; i++) {
        var options = {
          year: "numeric", month: "2-digit", day: "2-digit"};
        var data = new Date(resp.data[i].date_open.replace(/-/g, '/'));
        resp.data[i].date_open = data.toLocaleDateString("pt-BR", options);
        resp.data[i].price_total = parseFloat(resp.data[i].price_total)
          .toLocaleString("en-us", { minimumFractionDigits: 2, currency: 'USD' })
          .replace(",", "");
        //resp.data[i].cost = resp.data[i].cost.toLocaleString('en-us', {minimumFractionDigits: 2}).replace(',', '');
      }

      commit(types.GET_AMOUNT, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      //console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const addAmounts = async ({ commit }, payload) => {

  var options = {
    year: "numeric", month: "2-digit", day: "2-digit"};
  var dateTime = new Date();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  var second = dateTime.getSeconds();
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var time = hour + ":" + minute + ":" + second;
  var data = new Date();
  var date_add = data.toLocaleDateString("pt-BR", options);
  var date_open =
    date_add.substring(6, 10) +
    "-" +
    date_add.substring(3, 5) +
    "-" +
    date_add.substring(0, 2);

  payload.date_open = date_open + " " + time,

  await http
    .post("amount", payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const updateAmounts = async ({ commit }, payload) => {

  var route = "amount/" + payload.id;

  var dateTime = new Date();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  var second = dateTime.getSeconds();
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  var time = hour + ":" + minute + ":" + second;
  var data = new Date();
  var date_current = data.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
  var date_reopen =
    date_current.substring(6, 10) +
    "-" +
    date_current.substring(3, 5) +
    "-" +
    date_current.substring(0, 2);
  payload.date_reopen = date_reopen + " " + time;

  await http
    .put(route, payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const closeAmounts = async ({ commit }, payload) => {

  var route = "closeamount/" + payload.id;

    var dateTime = new Date();
    var hour = dateTime.getHours();
    var minute = dateTime.getMinutes();
    var second = dateTime.getSeconds();
    if (hour.toString().length == 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length == 1) {
      minute = "0" + minute;
    }
    if (second.toString().length == 1) {
      second = "0" + second;
    }
    var time = hour + ":" + minute + ":" + second;
    var data = new Date();
    var date_current = data.toLocaleDateString("pt-BR", {
      timeZone: "UTC",
    });
    var date_close =
      date_current.substring(6, 10) +
      "-" +
      date_current.substring(3, 5) +
      "-" +
      date_current.substring(0, 2);
    payload.date_close = date_close + " " + time;
    await http
    .put(route, payload, {})
    .then((resp) => {
      commit(types.SET_MESSAGE, resp.data);
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      //console.log(error);
      commit(types.SET_ERROR, true);
    });
};

export const deleteAmounts = async ({ commit }, payload) => {
  var route = "amount/" + payload.id;
  await http
    .delete(route, {})
    .then((resp) => {
      commit(types.SET_ERROR, false);
    })
    .catch((error) => {
      commit(types.SET_ERROR, true);
    });
};

export const setExpanded = async ({ commit }, payload) => {
  await commit(types.SET_EXPANDED, payload);
};

export const clearAmounts = async ({ commit }, payload) => {
  await commit(types.GET_AMOUNT, []);
};

export const setErrorApi = async ({ commit }, payload) => {
  await commit(types.SET_ERROR, payload);
};
