export default {
  headers: [
    {
      text: "ID",
      align: "start",
      value: "id",
      divider: true,
    },
    { text: "Nome", value: "name", divider: true },
    { text: "Logo", value: "logo", divider: true },
    { text: "Ações", value: "actions", sortable: false },
  ],
  companies: [],
  items_companies: [],
  titleTable: 'EMPRESAS',
  error_api: false,
}