import axios from "axios";
import Cookie from "js-cookie";

export const http = require("axios").create({
  baseURL: "http://api.kiosksystem.com.br/api/",
  //baseURL: "http://127.0.0.1:8000/api/",
  responseType: "json",
});

http.interceptors.request.use(async (config) => {
  const token = await Cookie.get("_my_token");
  if (token){
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
},(error) => {
  //console.log(error);
})
