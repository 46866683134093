import * as types from './mutation-types';
import { http } from "../../../services/config";
import Cookie from "js-cookie";

export const getLogin = async ({ commit }, payload) => {
  await http.post("auth/login", payload, {}).then((resp) => {
    commit(types.SET_TOKEN, resp.data.access_token);
    Cookie.set("_my_token", resp.data.access_token);
  })
  .catch((error) => {
    //console.log(error);
    if (error.response) {
      //console.log(error.response);
      // A requisição foi feita e o servidor respondeu com um código de status
      // que sai do alcance de 2xx
        if (error.response.status != 200) {
        switch (error.response.status) {
          case 401:
            //this.error_login = "Usuário e/ou senha inválidos!";
            commit(types.SET_ERROR, "Usuário e/ou senha inválidos!");
            break;
          case 404:
            //this.error_login = "Sistema offline. Contate o fornecedor!";
            commit(types.SET_ERROR, "Sistema offline. Contate o fornecedor!");
            break;
          default:
            //this.error_login =
            //  "Erro ao efetuar o login. Tente novamente!";
            commit(types.SET_ERROR, "Erro ao efetuar o login. Tente novamente!");
        }
      } else if (error.request) {
        // A requisição foi feita mas nenhuma resposta foi recebida
        // `error.request` é uma instância do XMLHttpRequest no navegador e uma instância de
        // http.ClientRequest no node.js
        //this.error_login = "Erro ao efetuar o login. Tente novamente!";
        commit(types.SET_ERROR, "Erro ao efetuar o login. Tente novamente!");
      } else {
        //this.error_login = "Erro ao efetuar o login. Tente novamente!";
        commit(types.SET_ERROR, "Erro ao efetuar o login. Tente novamente!");
      }
    } else {
//      this.error_login = "Sistema offline. Contate o fornecedor! 3333";
      commit(types.SET_ERROR, "Sistema offline. Contate o fornecedor!");
    }
  });

}

export const getMe = async ({ commit }, payload) => {
  await http.post("auth/me", {}, {}).then((resp) => {
    commit(types.SET_USER, resp.data);
  });
}

export const setError = async ({ commit }, payload) => {
  commit(types.SET_ERROR, payload);
}