import Vue from 'vue'
import Vuex from 'vuex'
import { store as company} from './../views/company'
import { store as auth} from './../views/auth'
import { store as user} from './../views/user'
import { store as product} from './../views/product'
import { store as amount} from '../views/amount/amount'
import { store as itemamount} from '../views/amount/itemamount'
import { store as cashregisterpanel} from './../components/cashregisterpanel'
import { store as demandpanel} from './../components/demandpanel'
import { store as cashregister} from './../views/cashregister/cashregister'
import { store as itemcashregister} from './../views/cashregister/itemcashregister'
import { store as general} from './general'

Vue.use(Vuex)

// initial state
let initialState = {
  company: company.state,
  auth: auth.state,
  user: user.state,
  product: product.state,
  amount: amount.state,
  itemamount: itemamount.state,
  cashregisterpanel: cashregisterpanel.state,
  demandpanel: demandpanel.state,
  cashregister: cashregister.state,
  general: general.state
}

export default new Vuex.Store({
  modules: {
    company,
    auth,
    user,
    product,
    amount,
    itemamount,
    cashregisterpanel,
    demandpanel,
    cashregister,
    itemcashregister,
    general
  },
  mutations: {
    reset (state) {
      router.go();
    }
  },
  state: {
    teste: 'TESTE',
  },
})
