import * as types from './mutation-types';

export default {
  [types.SET_CASHREGISTER] (state, payload){
    state.cashregister = payload;
  },
  [types.GET_ITEMCASHREGISTER] (state, payload){
    state.item_cashregister = payload;
  },
  [types.ADD_ITEMCASHREGISTER] (state, payload){
    state.item_cashregister = payload;
  },
  [types.SET_USERRESPONSIBLE] (state, payload){
    state.user_responsible_label = payload;
  },
  [types.SET_COLORCASHREGISTER] (state, payload){
    state.color_initial_cash = payload;
  },
  [types.SET_COLORSTATUS] (state, payload){
    state.color_status = payload;
  },
  [types.SET_COLORSCURRENTCASH] (state, payload){
    state.color_current_cash = payload;
  },
  [types.SET_DATECASHOPEN] (state, payload){
    state.date_cash = payload;
  },
  [types.SET_OPENCASHREGISTER] (state, payload){
    state.is_open_cashregister = payload;
  },
  [types.SET_VALUEINITIAL] (state, payload){
    state.value_initial = payload;
  },
  [types.SET_VALUEFINAL] (state, payload){
    state.value_final = payload;
  },
  [types.SET_VALUEFINALORIGIN] (state, payload){
    state.value_final_origin = payload;
  },
  [types.SET_MESSAGE] (state, payload){
    state.message = payload;
  },
  [types.SET_ERROR] (state, payload){
    state.error_api = payload;
  },
}