import * as types from "./mutation-types";
import { http } from "../../../services/config";
import bcrypt from "bcryptjs";

export const getUsers = async ({ commit }, payload) => {
  await http.post("users", payload, {}).then((resp) => {
    commit(types.GET_USERS, resp.data);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const addUsers = async ({ commit }, payload) => {
  const salt = bcrypt.genSaltSync(10);
  payload.password = bcrypt.hashSync(payload.password, salt);
  await http.post("user", payload, {}).then((resp) => {
    commit(types.SET_MESSAGE, resp.data);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const updateUsers = async ({ commit }, payload) => {
  if (payload.alter_password) {
    const salt = bcrypt.genSaltSync(10);
    payload.password = bcrypt.hashSync(payload.password, salt);
  }
  var route = "user/" + payload.id;
  await http.put(route, payload, {}).then((resp) => {
    commit(types.SET_MESSAGE, resp.data);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const deleteUsers = async ({ commit }, payload) => {
  var route = "user/" + payload.id;
  await http.delete(route, {}).then((resp) => {
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  }); 
};

export const clearUsers = async ({ commit }, payload) => {
  await commit(types.GET_USERS, []);
};

export const setErrorApi = async ({ commit }, payload) => {
  await commit(types.SET_ERROR, payload);
};

