import * as types from './mutation-types';

export default {
  [types.GET_COMPANIES] (state, payload){
    state.companies = payload;
  },
  [types.GET_COMPANIES_SELECT] (state, payload){
    state.items_companies = payload;
  },
  [types.ADD_COMPANIES] (state, payload){
    state.companies = payload;
  },
  [types.SET_ERROR] (state, payload){
    state.error_api = payload;
  },
}