import { http } from "./config";
import bcrypt from "bcryptjs";

export default {
  get: () => {
    return http.post("auth/me", {}, {});
  },

  getUserResponsible: (company_id) => {
    var body = {
      company_id: company_id,
    };
    return http.post("usersresponsible", body, {});
  },

  list: (company_id, level_current) => {
    var body = {
      company_id: company_id,
      level_user: level_current,
    };
    return http.post("users", body);
  },

  update: (body, id, alter_password) => {
    if (alter_password) {
      const salt = bcrypt.genSaltSync(10);
      body.password = bcrypt.hashSync(body.password, salt);
    }
    var route = "user/" + id;
    return http.put(route, body, {});
  },

  insert: (body) => {
    const salt = bcrypt.genSaltSync(10);
    body.password = bcrypt.hashSync(body.password, salt);
    return http.post("user", body, {});
  },

  delete: (id) => {
    var route = "user/" + id;
    return http.delete(route, {} );
  },
};
