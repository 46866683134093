export const GET_ITEMCASHREGISTER = 'CASHREGISTERS/GET_ITEMCASHREGISTER';
export const SET_CASHREGISTER = 'CASHREGISTERS/SET_CASHREGISTER';
export const ADD_CASHREGISTER = 'CASHREGISTERS/ADD_CASHREGISTER';
export const SET_USERRESPONSIBLE = 'CASHREGISTERS/SET_USERRESPONSIBLE';
export const SET_COLORCASHREGISTER = 'CASHREGISTERS/SET_COLORCASHREGISTER';
export const SET_COLORSTATUS = 'CASHREGISTERS/SET_COLORSTATUS';
export const SET_COLORSCURRENTCASH = 'CASHREGISTERS/SET_COLORSCURRENTCASH';
export const SET_DATECASHOPEN = 'CASHREGISTERS/SET_DATECASHOPEN';
export const SET_OPENCASHREGISTER = 'CASHREGISTERS/SET_OPENCASHREGISTER';
export const SET_VALUEINITIAL = 'CASHREGISTERS/SET_VALUEINITIAL';
export const SET_VALUEFINAL = 'CASHREGISTERS/SET_VALUEFINAL';
export const SET_VALUEFINALORIGIN = 'CASHREGISTERS/SET_VALUEFINALORIGIN';
export const SET_MESSAGE = 'CASHREGISTERS/SET_MESSAGE';
export const SET_ERROR = 'CASHREGISTERS/SET_ERROR';
