export default {
  item_cashregister: [],
//  items_userresponsible: [],
  operation_type_add_items: [
    { text: "Entrada", value: "Entrada" },
    { text: "Saída", value: "Saída" },
  ],
  payment_type_add_items: [
    { text: "Dinheiro", value: "Dinheiro" },
    { text: "Cartão", value: "Cartão" },
    { text: "Cheque", value: "Cheque" },
    { text: "PIX", value: "PIX" },
    { text: "Transferência", value: "Transferência" },
    { text: "Depósito", value: "Depósito" },
  ],
  headers: [
    {
      text: "Data Lançamento",
      align: "start",
      value: "date",
      //divider: true,
    },
    { text: "Descrição", value: "description" },//divider: true, },
    { text: "Tipo Operação", value: "operation_type" },//divider: true, },
    { text: "Tipo Pagamento", value: "payment_type" },//divider: true, },
    { text: "Valor (R$)", value: "value_register", align: "end" },
  ],
  cashregister: [],
  message: '',
  error_api: false,
  user_responsible_label:'',
  color_initial_cash:'',
  color_status:'',
  color_current_cash: '',
  date_cash:'',
  value_initial:'',
  value_final_origin:'',
  value_final:'',
  is_open_cashregister: false,
}