import Vue from 'vue'
import VueRouter from 'vue-router'
import Guard from '../services/middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'noroute',
    component: () => import('../components/NoRouteView.vue'),
    //beforeEnter: Guard.auth,
  },
  {
    path: '/nopermission',
    name: 'nopermission',
    component: () => import('../components/NoPermissionView.vue'),
    //beforeEnter: Guard.auth,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/AuthView.vue'),
  },
  {
    path: ('/'),
    name: 'home',
    component: () => import('../views/home/HomeView.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/amount',
    name: 'amount',
    component: () => import('../views/amount/amount/AmountView.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/amountpanel',
    name: 'amountpanel',
    component: () => import('../components/amountpanel/AmountPanel.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/amountdetail/:id_amount',
    name: 'amountdetail',
    component: () => import('../views/amount/itemamount/AmountDetailView.vue'),
    beforeEnter: Guard.auth,
  }, 
  {
    path: '/cashregister',
    name: 'cashregister',
    component: () => import('../views/cashregister/cashregister/CashRegisterView.vue'),
    beforeEnter: Guard.auth,
  }, 
  {
    path: '/cashregisterdetail/:id_cashregister',
    name: 'cashregisterdetail',
    component: () => import('../views/cashregister/itemcashregister/CashRegisterDetailView.vue'),
    beforeEnter: Guard.auth,
  }, 
  {
    path: '/demandpanel',
    name: 'demandpanel',
    component: () => import('../components/demandpanel/DemandPanel.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/ProductView.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/UserView.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company/CompanyView.vue'),
    beforeEnter: Guard.auth,
  },
  //{
  //  path: '/report/amount',
  //  name: 'amountReport',
  //  component: () => import('../views/report/AmountReportView.vue'),
  //  beforeEnter: Guard.auth,
  //},
  //{
  //  path: '/report/demand',
  //  name: 'demandReport',
  //  component: () => import('../views/report/DemandReportView.vue'),
  //  beforeEnter: Guard.auth,
  //},
  //{
  //  path: '/report/cashregister',
  //  name: 'cashregisterReport',
  //  component: () => import('../views/report/CashregisterReportView.vue'),
  //  beforeEnter: Guard.auth,
  //},
  {
    path: '/report/billing',
    name: 'billingReport',
    component: () => import('../views/report/BillingReportView.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/user/ProfileView.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/company/SettingsView.vue'),
    beforeEnter: Guard.auth,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
