import * as types from "./mutation-types";
import { http } from "../../../services/config";

export const getCompanies = async ({ commit }, payload) => {
  await http.get("company", {}, {}).then((resp) => {
    commit(types.GET_COMPANIES, resp.data);
    commit(types.SET_ERROR, false);
  });
};

export const getCompaniesSelect = async ({ commit }, payload) => {
  await http.get("company", {}, {}).then((resp) => {
    var items = [];
    for (var i = 0; i < resp.data.length; i++) {
      items.push({
        text: resp.data[i].name,
        value: resp.data[i].id,
      });
    } 
    commit(types.GET_COMPANIES_SELECT, items);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const addCompanies = async ({ commit }, payload) => {
  await http.post("company", payload, {}).then((resp) => {
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  }); 
};

export const updateCompanies = async ({ commit }, payload) => {
  var route = "company/" + payload.id;
  await http.put(route, payload, {}).then((resp) => {
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    //console.log(error);
    commit(types.SET_ERROR, true);
  }); 
};

export const deleteCompanies = async ({ commit }, payload) => {
  var route = "company/" + payload.id;
  await http.delete(route, {}).then((resp) => {
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    //console.log(error);
    commit(types.SET_ERROR, true);
  }); 
};

export const clearCompanies = async ({ commit }, payload) => {
  await commit(types.GET_COMPANIES, []);
};

export const setErrorApi = async ({ commit }, payload) => {
  await commit(types.SET_ERROR, payload);
};

