export default {
  headers: [
    { text: "Nº", align: "start", value: "number_amount" }, // divider: true},
    { text: "Cliente", align: "start", value: "name" }, // divider: true},
    { text: "Abertura", value: "date_open" }, // divider: true},
    { text: "Descrição", value: "description" }, // divider: true},
    { text: "Total (R$)", align: "end", value: "price_total" }, // divider: true},
    { text: "Ações", value: "actions", sortable: false },
  ],
  amounts: [],
  //expanded_amount: [],
  payment_type_add_items: [
    { text: "Dinheiro", value: "Dinheiro" },
    { text: "Cartão", value: "Cartão" },
    { text: "Cheque", value: "Cheque" },
    { text: "PIX", value: "PIX" },
    { text: "Transferência", value: "Transferência" },
    { text: "Depósito", value: "Depósito" },
  ],
  message: '',
  error_api: false,
}