export default {
  headers: [
    {
      text: "Nome",
      align: "start",
      value: "name",
    },
    { text: "Abertura", value: "date_open" },
    { text: "Descrição", value: "description" },
    { text: "Total", value: "price_total" },
    { text: "Ações", value: "actions", sortable: false },
  ],
  itemamounts: [],
  status_demand: [
    { text: "Aberto", value: "Aberto" },
    { text: "Pronto", value: "Pronto" },
    { text: "Entregue", value: "Entregue" },
  ],
  message: '',
  error_api: false,
  expanded: [],
}