export default {
  headers: [
    {
      text: "Qtde",
      align: "start",
      value: "quantity",
      //divider: true,
    },
    { text: "Produto", value: "product_description" }, // divider: true},
    { text: "Observação", value: "observation" },
    { text: "Cliente", value: "name" }, // divider: true},
    { text: "Nº", value: "number_amount" }, // divider: true},
    { text: "Situação", value: "status" }, // divider: true},
    { text: "Ações", value: "actions" }, // divider: true},
  ],
  demands: [],
  message: '',
  error_api_demand: false,
}