import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import { CoolSelectPlugin } from 'vue-cool-select'
import "./plugins/vuetify-money.js";

export const eventBus = new Vue();

Vue.use(VueResource);
Vue.use(CoolSelectPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
