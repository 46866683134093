import * as types from "./mutation-types";
import { http } from "../../../services/config";

export const getProducts = async ({ commit }, payload) => {
  await http.post("products", payload, {}).then((resp) => {
    for (var i = 0; i < resp.data.length; i++) {
      resp.data[i].price = parseFloat(resp.data[i].price)
        .toLocaleString("en-us", { minimumFractionDigits: 2 })
        .replace(",", "");
        resp.data[i].cost = parseFloat(resp.data[i].cost)
        .toLocaleString("en-us", { minimumFractionDigits: 2 })
        .replace(",", "");
    }
    commit(types.GET_PRODUCTS, resp.data);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const addProducts = async ({ commit }, payload) => {
  await http.post("product", payload, {}).then((resp) => {
    commit(types.SET_MESSAGE, resp.data);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const updateProducts = async ({ commit }, payload) => {
  var route = "product/" + payload.id;
  await http.put(route, payload, {}).then((resp) => {
    commit(types.SET_MESSAGE, resp.data);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  });
};

export const deleteProducts = async ({ commit }, payload) => {
  var route = "product/" + payload.id;
  await http.delete(route, {}).then((resp) => {
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  }); 
};

export const clearProducts = async ({ commit }, payload) => {
  await commit(types.GET_PRODUCTS, []);
};

export const setErrorApi = async ({ commit }, payload) => {
  await commit(types.SET_ERROR, payload);
};

