import * as types from "./mutation-types";
import { http } from "../../../services/config";

export const getCompany = async ({ commit }, payload) => {
  var route = "company/" + payload.id;
  await http.get(route, {}, {}).then((resp) => {
    commit(types.SET_AUTORELOAD, resp.data.auto_reload);
    commit(types.SET_TIMERELOAD, resp.data.time_reload);
    commit(types.SET_USECOLORS, resp.data.use_colors);
    commit(types.SET_AMOUNTHOME, resp.data.amount_home);
    commit(types.SET_DEMANDHOME, resp.data.demand_home);
    commit(types.SET_CASHREGISTERHOME, resp.data.cashregister_home);
    commit(types.SET_COMPANYNAME, resp.data.name);
    commit(types.SET_ERROR, false);
  })
  .catch((error) => {
    commit(types.SET_ERROR, true);
  }); 
};

export const closeAmountPanel = async ({ commit }, payload) => {
  commit(types.SET_AMOUNTHOME, payload);
};

export const closeDemandPanel = async ({ commit }, payload) => {
  commit(types.SET_DEMANDHOME, payload);
};

export const closeCashregisterPanel = async ({ commit }, payload) => {
  commit(types.SET_CASHREGISTERHOME, payload);
};

export const setAutoReload = async ({ commit }, payload) => {
  commit(types.SET_AUTORELOAD, payload);
};

export const setDrawer = async ({ commit }, payload) => {
  commit(types.SET_DRAWER, payload);
};

export const setApiFail = async ({ commit }, payload) => {
  commit(types.SET_API_FAIL, payload);
};
