import * as types from './mutation-types';

export default {
  [types.GET_PRODUCTS] (state, payload){
    state.products = payload;
  },
  [types.ADD_PRODUCTS] (state, payload){
    state.products = payload;
  },
  [types.SET_MESSAGE] (state, payload){
    state.message = payload;
  },
  [types.SET_ERROR] (state, payload){
    state.error_api = payload;
  },
}