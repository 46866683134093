export const SET_DRAWER = 'GENERALS/SET_DRAWER';
export const SET_AUTORELOAD = 'GENERALS/SET_AUTORELOAD';
export const SET_TIMERELOAD = 'GENERALS/SET_TIMERELOAD';
export const SET_USECOLORS = 'GENERALS/SET_USECOLORS';
export const SET_AMOUNTHOME = 'GENERALS/SET_AMOUNTHOME';
export const SET_DEMANDHOME = 'GENERALS/SET_DEMANDHOME';
export const SET_CASHREGISTERHOME = 'GENERALS/SET_CASHREGISTERHOME';
export const SET_COMPANYNAME = 'GENERALS/SET_COMPANYNAME';
export const SET_ERROR = 'GENERALS/SET_ERROR';
export const SET_API_FAIL = 'GENERALS/SET_API_FAIL';
