import state from './state';
import mutations from './mutations';
import mutationsTypes from './mutation-types';
import * as actions from './actions';
import * as getters from './getters';

export default {
  state,
  actions,
  mutations,
  mutationsTypes,
  getters,
  namespaced: true
}

